<template>
  <div class="list" v-loading="dataLoading">
    <el-form :inline="true">
      <el-form-item label="类型">
        <el-radio-group v-model="type">
          <el-radio-button label="-1">不限</el-radio-button>
          <el-radio-button label="1">Influencer</el-radio-button>
          <el-radio-button label="2">Advertiser</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="state">
          <el-radio-button label="-1">不限</el-radio-button>
          <el-radio-button label="1">已处理</el-radio-button>
          <el-radio-button label="0">未处理</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-button type="primary" @click="searchData">搜索</el-button>
    </el-form>

    <h2>共 {{ totalCount }} 记录</h2>

    <el-table
      :data="dataList"
      stripe
      style="width: 100%"
      row-class-name="data-row"
      :row-style="{ cursor: 'point' }"
    >
      <el-table-column prop="create_time" label="时间" width="160">
      </el-table-column>
      <el-table-column prop="ip" label="IP"> </el-table-column>
      <el-table-column label="类型" width="90">
        <template slot-scope="scope">
          {{ scope.row.type == 1 ? "Influencer" : "Advertiser" }}
        </template>
      </el-table-column>
      <el-table-column label="名字">
        <template slot-scope="scope">
          {{ scope.row.first_name + "-" + scope.row.last_name }}
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱"> </el-table-column>
      <el-table-column label="内容">
        <template slot-scope="scope">
          <el-button size="mini" plain @click="openContent(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="处理时间">
        <template slot-scope="scope">
          {{ scope.row.state == 1 ? scope.row.update_time : null }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleUpdate(scope.$index, scope.row)"
            v-if="scope.row.state == 0"
            >处理</el-button
          >
          <el-button v-else size="mini" type="success" disabled
            >已处理</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dataLoading: false,
      type: null,
      state: null,
      dataList: [],
      currentPage: 1,
      pageSize: 20,
      totalCount: 0,
    };
  },
  mounted() {
    this.fetchData(this.searchText);
  },
  methods: {
    searchData() {
      this.currentPage = 1;
      this.fetchData();
    },
    fetchData() {
      this.dataLoading = true;
      axios
        .get(process.env.VUE_APP_BASE_API + "/contact/list", {
          params: {
            pagesize: this.pageSize,
            page: this.currentPage,
            s: this.state
              ? this.state == "-1"
                ? null
                : this.state
              : this.state,
            t: this.type ? (this.type == "-1" ? null : this.type) : this.type,
          },
        })
        .then((res) => {
          this.dataLoading = false;
          const { data } = res;
          if (data.status === 200) {
            const { rows, total } = data.data;
            if (rows) {
              this.dataList = rows;
            }
            this.totalCount = total;
          } else {
            this.$message.error("获取数据失败");
          }
        })
        .catch((error) => {
          this.dataLoading = true;
          this.$message.error("获取数据失败");
        });
    },
    handleUpdate(index, data) {
      this.$confirm(`是否处理完成?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.updateState(data.id, index);
        })
        .catch(() => {});
    },
    updateState(id, index) {
      const loading = this.$loading({
        lock: true,
      });
      axios
        .post(process.env.VUE_APP_BASE_API + `/contact/handle`, { id: id })
        .then((res) => {
          loading.close();
          const { data } = res;
          if (data.status === 200) {
            this.dataList.splice(index, 1);
            this.$message({
              type: "success",
              message: "处理成功!",
            });
            this.searchData();
          } else {
            this.$message.error("处理失败");
          }
        })
        .catch((error) => {
          loading.close();
          this.dataLoading = true;
          this.$message.error("处理失败");
        });
    },
    openContent(data) {
      let content = data.content;
      if (content) {
        content = content.replaceAll("\n", "<br/>");
      }
      this.$alert(content, "", {
        dangerouslyUseHTMLString: true,
      });
    },
    handleDelete(index, data) {
      this.$confirm(`是否确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteContact(data.id, index);
        })
        .catch(() => {});
    },
    deleteContact(id, index) {
      const loading = this.$loading({
        lock: true,
      });
      axios
        .post(process.env.VUE_APP_BASE_API + `/contact/deleteContact`, {
          id: id,
        })
        .then((res) => {
          loading.close();
          const { data } = res;
          if (data.status === 200) {
            this.dataList.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.searchData();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((error) => {
          loading.close();
          this.dataLoading = true;
          this.$message.error("删除失败");
        });
    },
  },
};
</script>

<style lang="stylus">
.content-col {
  white-space: nowrap;
}
</style>
