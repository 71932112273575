import Vue from "vue";
import VueRouter from "vue-router";
import Kol from "../views/Kol.vue";
import KolEdit from "../views/KolEdit.vue";
import Contact from "../views/Contact.vue";
import Reward from "../views/Reward.vue";
import RewardEdit from "../views/RewardEdit.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Kol",
    component: Kol,
  },
  {
    path: "/kol/:id",
    name: "KolEdit",
    component: KolEdit,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/reward",
    name: "Reward",
    component: Reward,
  },
  {
    path: "/reward/:id",
    name: "RewardEdit",
    component: RewardEdit,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
