let OSS = require("ali-oss");

// let client = new OSS({
//   accessKeyId: "LTAI5tKjM8S4BR2WB4B2iFCp", // 你创建的Bucket时获取的
//   accessKeySecret: "MrfNbM6eBbPlW09hILYpRD6qsnzPxZ", // 你创建的Bucket时获取的
//   bucket: "gidao", // 你创建的Bucket名称
//   region: "oss-ap-southeast-1" //  你所购买oss服务的区域，默认oss-cn-hangzhou
// });

export const client = new OSS({
  accessKeyId: "LTAI5tKjM8S4BR2WB4B2iFCp", // 你创建的Bucket时获取的
  accessKeySecret: "MrfNbM6eBbPlW09hILYpRD6qsnzPxZ", // 你创建的Bucket时获取的
  bucket: "gidao", // 你创建的Bucket名称
  region: "oss-ap-southeast-1", //  你所购买oss服务的区域，默认oss-cn-hangzhou
  endpoint: "media.gidao.io",
  cname: true,
});

// // 上传
// export async function put(filePath, file) {
//   try {
//     let result = await client.put(filePath, file);
//     return result;
//   } catch (err) {
//     console.log(err);
//   }
// }

// // 删除
// export async function remove(filePath) {
//   try {
//     let result = await client.delete(filePath);
//     return result;
//   } catch (err) {
//     console.log(err);
//   }
// }
