<template>
  <div class="edit">
    <el-button type="primary" plain @click="$router.go(-1)" class="backbtn"
      >返回</el-button
    >
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="名字:">
        <el-input v-model="form.name" placeholder="Name"></el-input>
      </el-form-item>
      <el-form-item label="编号:">
        <el-input v-model="form.kolcode" placeholder="编号"></el-input>
      </el-form-item>
      <el-form-item label="排序:">
        <el-input
          v-model="form.sort"
          type="number"
          placeholder="数字(越大越靠前)"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别:">
        <el-radio-group v-model="form.gender">
          <el-radio-button label="男"></el-radio-button>
          <el-radio-button label="女"></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="国家:">
        <el-input v-model="form.nation" placeholder="国家"></el-input>
      </el-form-item>
      <el-form-item label="Twitter:">
        <el-input
          v-model="form.twitter_name"
          placeholder="Twitter Name"
        ></el-input>
        <el-input
          v-model="form.twitter_url"
          placeholder="Twitter URL"
        ></el-input>
      </el-form-item>
      <el-form-item label="Instagram:">
        <el-input
          v-model="form.instagram_name"
          placeholder="Instagram Name"
        ></el-input>
        <el-input
          v-model="form.instagram_url"
          placeholder="Instagram URL"
        ></el-input>
      </el-form-item>
      <el-form-item label="Tiktok:">
        <el-input
          v-model="form.tiktok_name"
          placeholder="Tiktok Name"
        ></el-input>
        <el-input v-model="form.tiktok_url" placeholder="Tiktok URL"></el-input>
      </el-form-item>
      <el-form-item label="Facebook:">
        <el-input
          v-model="form.facebook_name"
          placeholder="Facebook Name"
        ></el-input>
        <el-input
          v-model="form.facebook_url"
          placeholder="Facebook URL"
        ></el-input>
      </el-form-item>
      <el-form-item label="Youtube:">
        <el-input
          v-model="form.youtube_name"
          placeholder="Youtube Name"
        ></el-input>
        <el-input
          v-model="form.youtube_url"
          placeholder="Youtube URL"
        ></el-input>
      </el-form-item>
      <el-form-item label="封面:">
        <alioss-uloader
          ref="coverUpload"
          refStr="coverUpload"
          :limit="1"
          :defaultDatas="detailCover"
        ></alioss-uloader>
      </el-form-item>
      <el-form-item label="照片:">
        <alioss-uloader
          ref="listUpload"
          refStr="listUpload"
          :sort="true"
          :defaultDatas="detailImages"
        ></alioss-uloader>
      </el-form-item>
      <el-form-item label="视频:">
        <alioss-uloader
          ref="videoUpload"
          refStr="videoUpload"
          listType="text"
          :defaultDatas="detailVideo"
        ></alioss-uloader>
      </el-form-item>
      <el-form-item label="视频封面:">
        <alioss-uloader
          ref="videoCoverUpload"
          refStr="videoCoverUpload"
          :limit="1"
          :defaultDatas="detailVideoCover"
        ></alioss-uloader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveData" :loading="formLoading"
          >保 存</el-button
        >
        <el-button type="primary" plain @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import AliOssUploader from "../components/AliOssUploader";
import axios from "axios";

export default {
  data() {
    return {
      formLoading: false,
      form: {
        name: null,
        sort: null,
        kolcode: null,
        twitter_name: null,
        twitter_url: null,
        instagram_name: null,
        instagram_url: null,
        tiktok_name: null,
        tiktok_url: null,
        facebook_name: null,
        facebook_url: null,
        youtube_name: null,
        youtube_url: null,
        gender: null,
        nation: null,
      },
      dialogImageUrl: "",
      dialogVisible: false,
      kolId: null,
      detailCover: [],
      detailImages: [],
      detailVideo: [],
      detailVideoCover: [],
    };
  },
  mounted() {
    const paramsId = this.$route.params.id;
    if (paramsId !== "create") {
      this.kolId = paramsId;
      this.fetchData();
    }
  },
  components: {
    "alioss-uloader": AliOssUploader,
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
      });
      axios
        .get(process.env.VUE_APP_BASE_API + `/kol/${this.kolId}`)
        .then((res) => {
          loading.close();
          const { data } = res;
          if (data.status === 200) {
            const { data: detail } = data;
            this.form = { ...detail };
            const { library_images, video, video_cover, cover_image } = detail;
            if (cover_image) {
              this.detailCover = [{ name: "封面", url: cover_image }];
            }
            if (video_cover) {
              this.detailVideoCover = [{ name: "视频封面", url: video_cover }];
            }
            if (library_images) {
              const images = JSON.parse(library_images);
              if (images && Array.isArray(images)) {
                this.detailImages = images.map((url) => {
                  return { url: url, name: "images" };
                });
              }
            }
            if (video) {
              this.detailVideo = [{ name: "视频.mp4", url: video }];
            }
          } else {
            this.$message.error("获取数据失败");
          }
        })
        .catch((error) => {
          loading.close();
          this.$message.error("获取数据失败");
        });
    },
    saveData() {
      const name = this.form.name;
      if (!name || name == "") {
        this.$message.error("请输入名字");
        return;
      }

      const coverImage =
        this.$refs["coverUpload"].$refs["coverUpload"].uploadFiles;
      let coverUrl = null;
      if (coverImage && coverImage.length > 0) {
        coverUrl = coverImage[0].url;
      }

      const listImages =
        this.$refs["listUpload"].$refs["listUpload"].uploadFiles;
      let listImageUrls = listImages.map(({ url }) => url);

      const video = this.$refs["videoUpload"].$refs["videoUpload"].uploadFiles;
      let videoUrl = null;
      if (video && video.length > 0) {
        videoUrl = video[0].url;
      }

      const videoCoverUpload =
        this.$refs["videoCoverUpload"].$refs["videoCoverUpload"].uploadFiles;
      let videoCoverUrl = null;
      if (videoCoverUpload && videoCoverUpload.length > 0) {
        videoCoverUrl = videoCoverUpload[0].url;
      }

      this.formLoading = true;
      const apiUrl =
        this.kolId && this.kolId > 0 ? `/kolupdate/${this.kolId}` : "/koladd";
      axios
        .post(process.env.VUE_APP_BASE_API + apiUrl, {
          ...this.form,
          coverUrl,
          listImageUrls,
          videoUrl,
          videoCoverUrl,
        })
        .then((res) => {
          this.formLoading = false;
          const { data } = res;
          if (data.status === 200) {
            const { id } = data.data;
            if (this.kolId && this.kolId > 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              setTimeout(() => {
                this.$router.push("/kol/" + id);
              }, 1000);
            }
          } else {
            this.$message.error("保存失败");
          }
        })
        .catch((error) => {
          console.log(error);
          this.formLoading = false;
          this.$message.error("保存失败");
        });
    },
  },
};
</script>

<style lang="stylus">
.backbtn
  margin-bottom 24px
.edit
  .el-form
    width 600px
    .el-form-item__content
      .el-input:nth-child(2)
        margin-top 12px
</style>
