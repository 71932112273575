<template>
  <div>
    <el-upload
      action=""
      list-type="picture-card"
      :auto-upload="true"
      :http-request="handleUpload"
      :ref="refStr"
      :on-change="handleChange"
      :file-list="defaultDatas"
      v-if="listType != 'text'"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }" :class="{ loading: file.loading }">
        <div class="loading-tip" v-if="file.loading">正在上传...</div>
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in"></i>
          </span>
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
          <span
            class="el-upload-list__item-move"
            v-if="sort"
            @click="moveImage(file, 'left')"
          >
            <i class="el-icon-arrow-left"></i>
          </span>
          <span
            class="el-upload-list__item-move"
            v-if="sort"
            @click="moveImage(file, 'right')"
          >
            <i class="el-icon-arrow-right"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" v-if="listType != 'text'">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-upload
      action=""
      :auto-upload="true"
      :http-request="uploadVideo"
      :ref="refStr"
      :on-change="handleChange"
      v-if="listType === 'text'"
      :before-upload="beforeUploadVideo"
      list-type="picture"
      :limit="1"
      :file-list="defaultDatas"
    >
      <el-button size="small" type="success" plain>点击上传</el-button>
      <template slot="file" slot-scope="{ file }">
        <img
          :src="require(`../assets/mp4.png`)"
          alt=""
          class="el-upload-list__item-thumbnail"
        />
        <a class="el-upload-list__item-name" target="_blank" :href="file.url"
          ><i class="el-icon-document"></i
          >{{ file.loading ? "正在上传..." : file.name }}
        </a>
        <i class="el-icon-close" @click="handleRemove"></i>
      </template>
    </el-upload>
  </div>
</template>

<script>
import { client } from "../utils/aliUpload";
export default {
  props: {
    limit: {
      type: Number,
      default: -1,
    },
    refStr: String,
    sort: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "picture-card",
    },
    defaultDatas: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  methods: {
    handleRemove(file) {
      let fileList = this.$refs[this.refStr].uploadFiles;
      let index = fileList.findIndex((fileItem) => {
        return fileItem.uid === file.uid;
      });
      fileList.splice(index, 1);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleUpload(item) {
      const { uid } = item.file;
      let targetIndex = -1;
      this.$refs[this.refStr].uploadFiles.forEach((item, index) => {
        const { uid: fileUid } = item;
        if (fileUid == uid) {
          targetIndex = index;
          return false;
        }
      });
      if (targetIndex == -1) return;
      this.$refs[this.refStr].uploadFiles[targetIndex].loading = true;
      let fileName = item.file.name;
      const split = fileName.split(".");
      const suffix = split && split.length > 0 ? split[split.length - 1] : "";
      fileName = new Date().getTime() + "." + suffix;
      client
        .put(fileName, item.file)
        .then((data) => {
          console.log(data);
          const { res } = data;
          if (res && res.statusCode && res.statusCode == 200) {
            //上传成功
            const { url } = data; //访问的URL
            console.log("上传成功", url);
            this.$refs[this.refStr].uploadFiles[targetIndex].loading = false;
            this.$refs[this.refStr].uploadFiles[targetIndex].url = url;
          }
        })
        .catch((err) => {
          this.$message.error("上传失败");
          console.log("上传失败", err);
          let fileList = this.$refs[this.refStr].uploadFiles;
          fileList.splice(targetIndex, 1);
        });
    },
    handleChange(file, fileList) {
      if (this.limit === 1) {
        if (fileList.length > 1) {
          fileList.splice(0, 1);
        }
      }
    },
    moveImage(item, dir) {
      let fileList = this.$refs[this.refStr].uploadFiles;
      const index = fileList.indexOf(item);
      if (index == -1) return;

      if (dir == "left") {
        if (index > 0) {
          const prevIndex = index - 1;
          fileList[index] = fileList.splice(prevIndex, 1, fileList[index])[0];
        }
      } else {
        if (index + 1 != fileList.length) {
          const nextIndex = index + 1;
          fileList[index] = fileList.splice(nextIndex, 1, fileList[index])[0];
        }
      }
    },
    beforeUploadVideo(file) {
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error("选择视频格式文件");
        return false;
      }
    },
    uploadVideo(item) {
      const fileItem = this.$refs[this.refStr].uploadFiles[0];
      fileItem.loading = true;
      let fileName = item.file.name;
      const split = fileName.split(".");
      const suffix = split && split.length > 0 ? split[split.length - 1] : "";
      fileName = new Date().getTime() + "." + suffix;
      client
        .put(fileName, item.file)
        .then((data) => {
          const { res } = data;
          if (res && res.statusCode && res.statusCode == 200) {
            //上传成功
            const { url } = data; //访问的URL
            fileItem.loading = false;
            fileItem.url = url;
          }
        })
        .catch((err) => {
          this.$message.error("上传视频失败");
          let fileList = this.$refs[this.refStr].uploadFiles;
          fileList.splice(0, 1);
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.el-upload-list
  .loading
    position relative
    &:before
      content ""
      position absolute
      left 0
      top 0
      width 100%
      height 100%
      background-color rgba(0, 0, 0, .8)
      z-index 998
    .loading-tip
      position absolute
      top 50%
      transform: translateY(-50%)
      text-align center
      width 100%
      color #fff
      z-index 999
</style>
