import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {
  Container,
  Header,
  Aside,
  Main,
  Link,
  Menu,
  MenuItem,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Button,
  Table,
  TableColumn,
  Upload,
  Dialog,
  Loading,
  Message,
  MessageBox,
  Pagination,
  Image,
  RadioButton,
  RadioGroup,
  DatePicker,
} from "element-ui";

Vue.config.productionTip = false;

Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Link);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Pagination);
Vue.use(Image);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(DatePicker);

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
