<template>
  <div class="app">
    <el-container>
      <el-header>GI DAO</el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu
            :default-active="$route.path"
            background-color="#263145"
            text-color="#BFCBCF"
            active-text-color="#409EFF"
            :router="true"
          >
            <el-menu-item index="/">
              <span slot="title">Kol</span>
            </el-menu-item>
            <el-menu-item index="/contact">
              <span slot="title">Contact</span>
            </el-menu-item>
            <el-menu-item index="/reward">
              <span slot="title">Token激励</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main :style="{ height: mainHeight + 'px' }">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import "./assets/common.styl";
export default {
  name: "App",
  data() {
    return {
      navActiveIndex: "/",
      mainHeight: 0,
    };
  },
  mounted() {
    this.mainHeight = window.innerHeight - 60;
  },
};
</script>
