<template>
  <div class="list" v-loading="dataLoading">
    <el-form :inline="true">
      <el-form-item label="">
        <el-input v-model="searchText" placeholder="按名字搜索"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="searchCode" placeholder="按编号搜索"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="nation" placeholder="按国家搜索"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="gender">
          <el-radio-button label="-1">不限</el-radio-button>
          <el-radio-button label="男"></el-radio-button>
          <el-radio-button label="女"></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-button type="primary" @click="searchData">搜索</el-button>
      <el-button type="success" @click="$router.push('/kol/create')"
        >新增</el-button
      >
    </el-form>

    <h2>共 {{ totalCount }} 网红</h2>

    <el-table
      :data="dataList"
      stripe
      style="width: 100%"
      row-class-name="data-row"
      :row-style="{ cursor: 'point' }"
    >
      <el-table-column prop="sort" label="排序" width="80"> </el-table-column>
      <el-table-column prop="kolcode" label="编号" width="80">
      </el-table-column>
      <el-table-column label="名字" width="200">
        <template slot-scope="scope">
          <div class="name-info">
            <!-- <img :src="scope.row.cover_image" /> -->
            <el-image :src="makeImageResize(scope.row.cover_image)">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            <span>{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="gender" label="性别" width="80"> </el-table-column>
      <el-table-column prop="nation" label="国家"> </el-table-column>
      <el-table-column label="照片" width="100">
        <template slot-scope="scope">
          <h2>{{ photoCount(scope.row) }}</h2>
        </template>
      </el-table-column>
      <el-table-column label="视频" width="100">
        <template slot-scope="scope"> {{ videoExist(scope.row) }} </template>
      </el-table-column>
      <!-- <el-table-column label="Twitter">
        <template slot-scope="scope">
          <el-link
            type="primary"
            v-if="scope.row.twitter_name"
            :href="scope.row.twitter_url"
            target="_blank"
            >{{ scope.row.twitter_name }}</el-link
          >
        </template>
      </el-table-column> -->
      <el-table-column label="Instagram">
        <template slot-scope="scope">
          <el-link
            type="primary"
            v-if="scope.row.instagram_name"
            :href="scope.row.instagram_url"
            target="_blank"
            >{{ scope.row.instagram_name }}</el-link
          >
        </template>
      </el-table-column>
      <!-- <el-table-column label="Tiktok">
        <template slot-scope="scope">
          <el-link
            type="primary"
            v-if="scope.row.tiktok_name"
            :href="scope.row.tiktok_url"
            target="_blank"
            >{{ scope.row.tiktok_name }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="Facebook">
        <template slot-scope="scope">
          <el-link
            v-if="scope.row.facebook_name"
            :href="scope.row.facebook_url"
            target="_blank"
            type="primary"
            >{{ scope.row.facebook_name }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="Youtube">
        <template slot-scope="scope">
          <el-link
            type="primary"
            v-if="scope.row.youtube_name"
            :href="scope.row.youtube_url"
            target="_blank"
            >{{ scope.row.youtube_name }}</el-link
          >
        </template>
      </el-table-column> -->
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleClick(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="currentPage"
      layout="sizes, prev, pager, next"
      :total="totalCount"
      style="margin-top: 15px"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dataLoading: false,
      searchText: null,
      searchCode: null,
      dataList: [],
      currentPage: 1,
      pageSize: 20,
      totalCount: 0,
      gender: null,
      nation: null,
    };
  },
  mounted() {
    this.fetchData(this.searchText);
  },
  methods: {
    searchData() {
      this.currentPage = 1;
      this.fetchData();
    },
    fetchData() {
      this.dataLoading = true;
      axios
        .get(process.env.VUE_APP_BASE_API + "/kollist", {
          params: {
            name: this.searchText,
            code: this.searchCode,
            pagesize: this.pageSize,
            page: this.currentPage,
            s: this.gender
              ? this.gender == "-1"
                ? null
                : this.gender
              : this.gender,
            c: this.nation,
          },
        })
        .then((res) => {
          this.dataLoading = false;
          const { data } = res;
          if (data.status === 200) {
            const { rows, total } = data.data;
            if (rows) {
              this.dataList = rows;
            }
            this.totalCount = total;
          } else {
            this.$message.error("获取数据失败");
          }
        })
        .catch((error) => {
          this.dataLoading = true;
          this.$message.error("获取数据失败");
        });
    },
    photoCount(data) {
      const { library_images } = data;
      if (library_images) {
        const images = JSON.parse(library_images);
        if (images && Array.isArray(images)) {
          return images.length;
        }
      }
      return 0;
    },
    videoExist(data) {
      const { video } = data;
      if (video) return "有";
      else return "无";
    },
    handleClick({ id }) {
      this.$router.push("/kol/" + id);
    },
    handleDelete(index, data) {
      this.$confirm(`是否确定删除${data.name}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteKol(data.id, index);
        })
        .catch(() => {});
    },
    deleteKol(id, index) {
      const loading = this.$loading({
        lock: true,
      });
      axios
        .post(process.env.VUE_APP_BASE_API + `/koldelete/${id}`)
        .then((res) => {
          loading.close();
          const { data } = res;
          if (data.status === 200) {
            this.dataList.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((error) => {
          loading.close();
          this.dataLoading = true;
          this.$message.error("删除失败");
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchData();
    },
    makeImageResize(imgSrc) {
      return imgSrc + "?x-oss-process=image/resize,w_80";
    },
  },
};
</script>

<style lang="stylus" scoped>
.data-row
  h2
    margin 0
.name-info
  display flex
  align-items center
  .el-image
    width 80px
    height 80px
    background-color #ccc
    margin-right 10px
  span
    text-align left
    font-weight 500
</style>
