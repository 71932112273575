<template>
  <div class="list" v-loading="dataLoading">
    <el-form :inline="true">
      <el-form-item label="">
        <el-input v-model="address" placeholder="按地址搜索"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="discrod" placeholder="按Discrod名字搜索"></el-input>
      </el-form-item>
      <el-button type="primary" @click="searchData">搜索</el-button>
      <el-button type="success" @click="$router.push('/reward/new')"
        >新增</el-button
      >
    </el-form>

    <h2>共 {{ totalCount }} 记录</h2>

    <el-table :data="dataList" stripe style="width: 100%">
      <el-table-column prop="create_time" label="创建时间" width="160" />
      <el-table-column prop="update_time" label="更新时间" width="160" />
      <el-table-column label="地址" prop="address" />
      <el-table-column prop="discord" label="Discrod名字" />
      <el-table-column label="Token数量">
        <template slot-scope="scope">
          {{ tokenAmount(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dataLoading: false,
      address: null,
      discrod: null,
      dataList: [],
      totalCount: 0,
    };
  },
  mounted() {
    this.searchData();
  },
  methods: {
    searchData() {
      this.dataLoading = true;

      axios
        .post(process.env.VUE_APP_BASE_API + "/tokendist/list", {
          address: this.address,
          discord: this.discrod,
          admin: 1,
        })
        .then((res) => {
          this.dataLoading = false;
          const { data } = res;
          if (data.status === 200) {
            const { rows } = data.data;
            if (rows) {
              this.dataList = rows;
            }
            this.totalCount = rows.length;
          } else {
            this.$message.error("获取数据失败");
          }
        })
        .catch((error) => {
          this.dataLoading = true;
          this.$message.error("获取数据失败");
        });
    },
    tokenAmount({ details }) {
      if (!details || details === "") return 0;

      const detailsJson = JSON.parse(details);
      if (detailsJson && Array.isArray(detailsJson)) {
        let total = 0;
        detailsJson.forEach((item) => {
          total += parseFloat(item.token);
        });
        return total;
      }

      return 0;
    },
    handleUpdate({ id }) {
      this.$router.push("/reward/" + id);
    },
    handleDelete(index, data) {
      this.$confirm(`是否确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteReward(data.id, index);
        })
        .catch(() => {});
    },
    deleteReward(id, index) {
      const loading = this.$loading({
        lock: true,
      });
      axios
        .post(process.env.VUE_APP_BASE_API + `/tokendist/del`, {
          id: id,
        })
        .then((res) => {
          loading.close();
          const { data } = res;
          if (data.status === 200) {
            this.dataList.splice(index, 1);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.searchData();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((error) => {
          loading.close();
          this.dataLoading = true;
          this.$message.error("删除失败");
        });
    },
  },
};
</script>
