<template>
  <div class="edit">
    <el-button type="primary" plain @click="$router.push('/reward')"
      >返回</el-button
    >
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      style="margin-top: 20px"
    >
      <el-form-item label="地址:">
        <el-input v-model="form.address" placeholder="地址"></el-input>
      </el-form-item>
      <el-form-item label="Discord:">
        <el-input v-model="form.discord" placeholder="Discord"></el-input>
      </el-form-item>
      <div class="dash"></div>
      <div class="inline">
        <h2>总量: {{ amount }}</h2>
        <el-button type="success" @click="addLine">增加Token项</el-button>
        <el-button type="warning" @click="clear">清空</el-button>
      </div>
      <el-form
        class="form2"
        :inline="true"
        v-for="(item, index) in details"
        :key="index"
      >
        <el-form-item>
          <el-input
            v-model="item.token"
            placeholder="Token数量"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="item.reason" placeholder="原因"></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="item.datetime"
            type="date"
            placeholder="日期时间"
            default-time="00:00:00"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="danger" @click="delLine(index)"
            >X</el-button
          >
        </el-form-item>
      </el-form>
      <el-form-item class="mt">
        <el-button type="primary" @click="saveData" :loading="formLoading"
          >保 存</el-button
        >
        <el-button type="primary" plain @click="$router.push('/reward')"
          >返回</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      formLoading: false,
      rId: null,
      form: {
        address: null,
        discord: null,
      },
      details: [
        {
          token: null,
          datetime: null,
          reason: null,
        },
      ],
    };
  },
  mounted() {
    const paramsId = this.$route.params.id;
    if (paramsId !== "new") {
      this.rId = paramsId;
      this.loadData();
    }
  },
  computed: {
    amount: function () {
      let total = 0;
      this.details.forEach(({ token }) => {
        if (token) {
          total += parseFloat(token);
        }
      });
      return parseFloat(total);
    },
  },
  methods: {
    loadData() {
      const loading = this.$loading({
        lock: true,
      });
      axios
        .post(process.env.VUE_APP_BASE_API + `/tokendist/list`, {
          id: this.rId,
        })
        .then((res) => {
          loading.close();
          const { data } = res;
          if (data.status === 200) {
            const { rows } = data.data;
            if (rows && rows.length > 0) {
              const { address, discord, details: detailsStr } = rows[0];
              this.form.address = address;
              this.form.discord = discord;
              if (detailsStr) {
                this.details = JSON.parse(detailsStr);
              }
            }
          } else {
            this.$message.error("获取数据失败");
          }
        })
        .catch((err) => {
          this.$message.error("获取数据失败");
          loading.close();
        });
    },
    addLine() {
      this.details.push({
        token: null,
        datetime: null,
        reason: null,
      });
    },
    clear() {
      this.details = [
        {
          token: null,
          datetime: null,
          reason: null,
        },
      ];
    },
    delLine(index) {
      this.details.splice(index, 1);
    },
    saveData() {
      const address = this.form.address;
      if (!address || address == "") {
        this.$message.error("请输入地址");
        return;
      }

      const result = [];
      this.details.forEach((item) => {
        if (item.token && parseFloat(item.token) > 0) {
          result.push(item);
        }
      });

      this.formLoading = true;
      const apiUrl =
        this.rId && this.rId > 0
          ? `/tokendist/update/${this.rId}`
          : "/tokendist/new";

      axios
        .post(process.env.VUE_APP_BASE_API + apiUrl, {
          ...this.form,
          details: result,
        })
        .then((res) => {
          this.formLoading = false;
          const { data } = res;
          if (data.status === 200) {
            if (this.rId && this.rId > 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              const { id } = data.data;
              this.$message({
                message: "新增成功",
                type: "success",
              });
              setTimeout(() => {
                this.$router.push("/reward/" + id);
              }, 1000);
            }
          } else {
            this.$message.error("保存失败");
          }
        })
        .catch((error) => {
          console.log(error);
          this.formLoading = false;
          this.$message.error("保存失败");
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.form2
  width 700px
.inline
  display flex
  align-items center
  button
    margin-left 30px
.dash
  border-top 1px dashed #ccc
  margin 30px 0
.mt
  margin-top 60px
</style>
